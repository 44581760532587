import React, { useEffect } from 'react';

import Layout from '../components/Layout';
import SEO from '../components/seo';
import landingPageData from '../data/embarkablePageData';
import { CtaSimple } from '../components/CTABlock';
import { getObjectFromArray } from '../utilities/arrayFunctions';
import { IconCard } from '../components/StepCard';

//main page
const ProductPage = () => {
	const pageData = getObjectFromArray(landingPageData, 'name', 'Embarkable');

	useEffect(() => window.scrollTo(0, 0), []);

	return (
		<Layout>
			<SEO pageTitle={pageData.product.title} description={pageData.product.description} />

			<div className="mt-32 mb-32 full-grid">
				<div className="col-span-8 rounded-2xl">
					<div className="mt-12 text-3xl font-bold text-gray-800 lg:text-5xl text-gradient bg-gradient-to-r from-purple-600 to-pink-400 textBlock">
						Simple tools. Powerful results.
					</div>
					<div className="my-4 text-gray-800 lg:text-xl textBlock">
						Capture more meaningful feedback and build products your customers love.
					</div>
				</div>
			</div>

			<div className="mb-32 full-grid">
				{pageData.featureList.map((feature, index) => {
					//const colorIndex = (index < 10 ? index : Math.round(index / 10))
					const rndInt = Math.floor(Math.random() * (10 - 1 + 1));
					//const rndHue = Math.floor(Math.random() * (9 - 1 + 1))
					const colorArray = [
						'gray',
						'red',
						'yellow',
						'green',
						'blue',
						'indigo',
						'purple',
						'pink',
						'teal',
						'orange',
						'tan'
					];
					//const colorHue = ['100', '200', '300', '400', '500', '600', '700', '800', '900']
					const iconColor = `${colorArray[rndInt]}`; //-${colorHue[rndHue]}`

					return (
						<div className="grid-2" key={'key-' + index}>
							<IconCard data={feature} iconColor={iconColor} />
						</div>
					);
				})}
			</div>

			<div className="mb-32 full-grid">
				<div className="col-span-8 rounded-2xl">
					<CtaSimple eventPageName="product" />
				</div>
			</div>
		</Layout>
	);
};

export default ProductPage;
